import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CreateNetDto } from 'models/NetModels';
import { ErrorService } from 'services/error-service';
import { GeneralOfferService } from 'services/general-offer-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';

@autoinject
export class GeneralOfferNew {
  private generalOffer: Models.GeneralOffer = new Models.GeneralOffer();
  private originalObject: CreateNetDto = new CreateNetDto();
  private saving: boolean = false;

  constructor(
    private errorService: ErrorService,
    private utility: Utility,
    private dialogService: DialogService,
    private prompt: Prompt,
    private validationController: ValidationController,
    private validator: Validator,
    private generalOfferService: GeneralOfferService,
    private toastService: ToastService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private i18n: I18N
  ) {
    ValidationRules.ensure('CustomerId')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('SegmentId')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('InternalDepartmentId')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('OfferStatusId')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('GeneralOfferTypeId')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .on(this.generalOffer);
  }

  private attached() {
    this.originalObject = JSON.parse(JSON.stringify(this.generalOffer));
  }

  private activate(params) {
    this.generalOffer.PlannedDeliveryDate = null;
    this.generalOffer.NextFollowUp = null;
    this.generalOffer.ValidTo = null;
    this.generalOffer.OfferStatusId = 2;
  }

  private canDeactivate() {
    // NOTE: Will react even if you hade made no changes.
    // See issue #303.

    if (!this.originalObject || this.saving) {
      return true;
    }

    // fix dates
    this.generalOffer = this.utility.areEqualDateFix(this.generalOffer, [{ key: 'PlannedDeliveryDate' }]);
    this.originalObject = this.utility.areEqualDateFix(this.originalObject, [{ key: 'PlannedDeliveryDate' }]);
    this.generalOffer = this.utility.areEqualDateFix(this.generalOffer, [{ key: 'NextFollowUp' }]);
    this.originalObject = this.utility.areEqualDateFix(this.originalObject, [{ key: 'NextFollowUp' }]);
    this.generalOffer = this.utility.areEqualDateFix(this.generalOffer, [{ key: 'ValidTo' }]);
    this.originalObject = this.utility.areEqualDateFix(this.originalObject, [{ key: 'ValidTo' }]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!this.utility.areEqual(this.generalOffer, this.originalObject as any, true)) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.subFormOpenHeading',
            message: 'dialog.subFormOpenMessage',
            actions: {
              delete: { enabled: false },
              save: { enabled: false },
              cancel: { enabled: true, t: 'dialog.cancel' },
              dontsave: { enabled: false },
              continue: { enabled: true, t: 'dialog.continue' },
            },
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            return true;
          }
        });
    } else {
      return true;
    }
  }

  private createGeneralOffer() {
    this.generalOfferService
      .post(this.generalOffer)
      .then((res) => {
        this.saving = true;
        this.toastService.showSuccess('generalOffer.created');
        this.router.navigateToRoute('general-offers');
        this.eventAggregator.publish('generalOffersListReset');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private validateBeforeSave() {
    this.validator.validateObject(this.generalOffer).then((result) => {
      const errors = result.filter((validateResult) => {
        return !validateResult.valid;
      });
      if (errors.length > 0) {
        this.validationController.validate();
      } else {
        this.createGeneralOffer();
      }
    });
  }
}
