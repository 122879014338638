import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { UserModels } from 'models/UserModels';
import { CustomerService } from 'services/customer-service';
import { DepartmentService } from 'services/department-service';
import { ErrorService } from 'services/error-service';
import { GeneralOfferTypeService } from 'services/general-offer-type-service';
import { InternalDepartmentService } from 'services/internal-department-service';
import { OfferStatusService } from 'services/offer-status-service';
import { ProducerService } from 'services/producer-service';
import { SegmentService } from 'services/segment-service';
import { UserService } from 'services/user-service';

@autoinject
export class GeneralOfferFormBasic {

  @bindable private generalOffer: Models.GeneralOffer;
  private customers: Array<Models.Customer>;
  private users: Array<UserModels.User>;
  private departments: Array<Models.Department>;
  private segments: Array<Models.Segment>;
  private internalDepartments: Array<Models.InternalDepartment>;
  private locked: boolean = false;
  private generalOfferTypes: Array<Models.GeneralOfferType>;

  constructor(
    private errorService: ErrorService,
    private generalOfferTypeService: GeneralOfferTypeService,
    private segmentService: SegmentService,
    private internalDepartmentService: InternalDepartmentService,
    private customerService: CustomerService,
    private userService: UserService,
    private offerStatusService: OfferStatusService,
    private producerService: ProducerService,
    private departmentService: DepartmentService
  ) { }

  private attached() {
    this.getCustomers();
    this.getUsers();

    if (this.generalOffer && this.generalOffer.CustomerId) {
      this.getDepartments();

      if (this.generalOffer['CanEdit'] === false) {
        this.locked = true;
      }

    }

    if (this.generalOffer.SegmentId) {
      this.getGeneralOfferTypes();
    }

    this.userService
    .getCurrentUser()
    .then(async (res) => {

      this.segments = await this.segmentService.getAllCached();
      this.internalDepartments = await this.internalDepartmentService.getAllCached();

      this.segments = this.segments.filter((x) => !x.IsDeleted);
      this.internalDepartments = this.internalDepartments.filter((x) => !x.IsDeleted);

      if (!this.generalOffer.InternalDepartmentId && !this.generalOffer.SegmentId) {
        if (this.segments.filter((x) => x.LeaderId === res.Id).length === 1) {
          setTimeout(() => this.generalOffer.SegmentId = this.segments.filter((x) => x.LeaderId === res.Id)[0].Id);
        }

        if (this.internalDepartments.filter((x) => x.LeaderId === res.Id).length === 1) {
          setTimeout(() => this.generalOffer.InternalDepartmentId = this.internalDepartments.filter((x) => x.LeaderId === res.Id)[0].Id);
        }
      }

    })
    .catch((err) => this.errorService.handleError(err));

  }

  private getCustomers() {
    this.customerService
    .getAll('?$orderby=Name')
    .then((res) => {
      this.customers = res;
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getUsers() {
    this.userService
    .getAll()
    .then((res) => {
      this.users = res;

      this.userService.getCurrentUser()
      .then((result) => {
        if (!this.generalOffer.ResponsibleUserId) {
          this.generalOffer.ResponsibleUserId = result.Id;
        }
      })
      .catch((err) => this.errorService.handleError(err));

    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getDepartments() {
    this.departmentService
    .getAll('?$filter=CustomerId eq ' + this.generalOffer.CustomerId)
    .then((res) => {
      this.departments = res;
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getGeneralOfferTypes() {
    this.generalOfferTypeService
    .getAll('?$filter=SegmentId eq ' + this.generalOffer.SegmentId)
    .then((res) => {
      this.generalOfferTypes = res;
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getInternalDepartments() {

    if (!this.generalOffer.SegmentId) {
      return;
    }

    this.internalDepartmentService
    .getAll('?$filter=SegmentId eq ' + this.generalOffer.SegmentId)
    .then((res) => {
      this.internalDepartments = res.filter((x) => !x.IsDeleted);
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private setCustomerId(event) {
    this.generalOffer.CustomerId = event.detail.value;
    this.getDepartments();
  }

  private setSegmentId(event) {
    this.generalOffer.SegmentId = event.detail.value;
    this.getInternalDepartments();
    this.getGeneralOfferTypes();
  }

}
