import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { AuthorizeStep } from 'authorizeStep';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { GeneralOfferService } from 'services/general-offer-service';
import { InternalDepartmentService } from 'services/internal-department-service';
import { OfferStatusService } from 'services/offer-status-service';
import { SegmentService } from 'services/segment-service';
import { UserService } from 'services/user-service';

@autoinject
export class GeneralOffers {

    private generalOffers: Array<Models.GeneralOffer>;
    private filters: any = {};
    private segments: Array<Models.Segment>;
    private internalDepartments: Array<Models.InternalDepartment>;
    private isLeader: boolean = false;

    private sumQuantity: number;
    private sumValue: number;
    private sumMargin: number;

    constructor(
      private errorService: ErrorService,
      private generalOfferService: GeneralOfferService,
      private eventAggregator: EventAggregator,
      private segmentService: SegmentService,
      private internalDepartmentService: InternalDepartmentService,
      private offerStatusService: OfferStatusService,
      private userService: UserService
    ) {

    }

    private attached() {

      this.isLeader = AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('Leader') !== -1;

      this.eventAggregator.subscribe('generalOffersListReset', (res) => {
        this.getGeneralOffers();
      });

      let defaultFilters = JSON.parse(localStorage.getItem('GENERAL_OFFER_FILTERS'));
      if (defaultFilters) {
        this.filters = defaultFilters;
      }

      this.userService
      .getCurrentUser()
      .then(async (res) => {

        this.segments = await this.segmentService.getAllCached();
        this.internalDepartments = await this.internalDepartmentService.getAllCached();

        this.segments = this.segments.filter((x) => !x.IsDeleted);
        this.internalDepartments = this.internalDepartments.filter((x) => !x.IsDeleted);

        if (this.filters.SegmentId) {
          this.internalDepartments = this.internalDepartments.filter(x => x.SegmentId === this.filters.SegmentId);
        }

        /*
        if (this.segments.filter((x) => x.LeaderId === res.Id).length === 1) {
          this.filters.SegmentId = this.segments.filter((x) => x.LeaderId === res.Id)[0].Id;
        }

        if (this.internalDepartments.filter((x) => x.LeaderId === res.Id).length === 1) {
          this.filters.InternalDepartmentId = this.internalDepartments.filter((x) => x.LeaderId === res.Id)[0].Id;
        }
        */

        this.getGeneralOffers();

      })
      .catch((err) => this.errorService.handleError(err));

    }

    private getGeneralOffers() {

      let filterString = '';
      for (const field of Object.keys(this.filters)) {
        if (this.filters[field]) {
          if (filterString === '') {
            filterString = `$filter=${field} eq ${this.filters[field]}`;
          } else {
            filterString += ` and ${field} eq ${this.filters[field]}`;
          }
        }
      }

      this.generalOfferService
      .getAll('?' + filterString)
      .then((res) => {
        this.generalOffers = res;

        this.sumMargin = res.reduce((sum, offer) => sum + (offer.Margin || 0), 0);
        this.sumValue = res.reduce((sum, offer) => sum + (offer.Value || 0), 0);
        this.sumQuantity = res.reduce((sum, offer) => sum + (offer.Quantity || 0), 0);        
      })
      .catch((err) => this.errorService.handleError(err));
    }

    private getInternalDepartments() {
      if (!this.filters.SegmentId) {
        this.internalDepartments = [];
        return;
      }

      this.internalDepartmentService
      .getAllCached()
      .then((res) => {
        this.internalDepartments = res.filter((x) => !x.IsDeleted && x.SegmentId === this.filters.SegmentId);
      })
      .catch((err) => this.errorService.handleError(err));
    }

    private setFilterSegmentId(event) {      
      this.filters.SegmentId = event && event.detail && event.detail.value && event.detail.value != '' ? parseInt(event.detail.value) : null;      
      this.getInternalDepartments();      
      this.saveFilters();      
      this.getGeneralOffers();     
    }

    private setFilterInternalDepartmentId(event) {
      this.filters.InternalDepartmentId = event && event.detail && event.detail.value && event.detail.value != '' ? parseInt(event.detail.value) : null;
      this.saveFilters();
      this.getGeneralOffers();
    }

    private setFilterOfferStatusId(event) {
      this.filters.OfferStatusId = event?.detail?.value && event?.detail?.value != '' ? parseInt(event.detail.value) : null;
      this.saveFilters();
      this.getGeneralOffers();
    }

    private saveFilters() {
      localStorage.setItem('GENERAL_OFFER_FILTERS', JSON.stringify(this.filters));
    }

    private exportGeneralOffers() {
      this.generalOfferService.exportList(this.filters.SegmentId, this.filters.InternalDepartmentId, this.filters.OfferStatusId);
    }

}
