import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { FloaterTypeService } from 'services/floater-type-service';

@autoinject
export class FloaterTypeDetail {
  private floaterType: Models.FloaterType;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private floaterTypeService: FloaterTypeService
  ) {}

  private activate(params) {
    this.floaterTypeService
      .get(params.Id)
      .then( (res) => {
        this.floaterType = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateFloaterType() {
    this.floaterTypeService
      .put(this.floaterType, this.floaterType.Id)
      .then((res) => {
        this.eventAggregator.publish('floaterTypeListReset', 1);
        this.toastService.showSuccess('floaterType.updated');
        this.router.navigateToRoute('floater-type-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteFloaterType() {
    this.floaterTypeService
      .delete(this.floaterType.Id)
      .then((res) => {
        this.eventAggregator.publish('floaterTypeListReset', 1);
        this.toastService.showSuccess('floaterType.deleted');
        this.router.navigateToRoute('floater-type-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private gotoParentView() {
    this.eventAggregator.publish('floaterTypeListReset', 1);
    this.router.navigateToRoute('floater-type-list');
  }
}
