import { I18N } from 'aurelia-i18n';
import './history-dialog.scss';
import { ErrorService } from 'services/error-service';
import { AuditLogService } from './../../services/audit-log-service';
import { autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger("history-dialog");

@autoinject
export class HistoryDialog {
    private historyFilter;
    private historyData: Array<any>;

    constructor(private dialogController: DialogController,
        private auditLogService: AuditLogService,
        private errorService: ErrorService,
        private i18n: I18N) {                 
    }

    activate(historyFilter) {
        this.historyFilter = historyFilter;

        let language = this.i18n.getLocale();

        this.auditLogService.getByEntityOrParent(this.historyFilter.EntityType, this.historyFilter.EntityId)
            .then(historyData => {
                historyData.forEach(x => {                    
                    x._actionName = 'auditlog.actions.' + x.Action;
                    x._entityTypeName = 'entities.' + x.EntityType;
                    if (x.CustomActionCode) {
                        x._customActionCode = 'auditlog.customactions.' + x.CustomActionCode;                    
                    }

                    // if the details is a JSON object
                    if (x.CustomActionDetails && x.CustomActionDetails !== '') {
                        try {
                            let obj = JSON.parse(x.CustomActionDetails);

                            if (language === 'es' && obj.NameEs) {
                                x._customActionDetailsTranslated = obj.NameEs;
                            } else if (language !== 'nb-NO' && obj.NameEn) {
                                x._customActionDetailsTranslated = obj.NameEn;
                            } else if (obj.Name) {
                                x._customActionDetailsTranslated = obj.Name;
                            }
                        } catch (e) {
                            logger.debug('could not parse object');
                            // dont do anything, the template will use the x.CustomActionDetails directly
                        }
                    }
                });
                
                this.historyData = historyData;
            })
            .catch(err => this.errorService.handleError(err));
    }
}
