import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { GeneralOfferTypeService } from 'services/general-offer-type-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class GeneralOfferTypeDetail {
  private generalOfferType: Models.GeneralOfferType;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private generalOfferTypeService: GeneralOfferTypeService,
    private deleteDialogService: DeleteDialogService
  ) {}

  private activate(params) {
    this.generalOfferTypeService
      .get(params.Id)
      .then((res) => {
        this.generalOfferType = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateGeneralOfferType() {
    this.generalOfferTypeService
      .put(this.generalOfferType, this.generalOfferType.Id)
      .then((res) => {
        this.eventAggregator.publish('generalOfferTypeListReset', 1);
        this.toastService.showSuccess('generalOfferType.updated');
        this.router.navigateToRoute('general-offer-type-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteGeneralOfferType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.generalOfferTypeService
          .delete(this.generalOfferType.Id)
          .then((res) => {
            this.eventAggregator.publish('generalOfferTypeListReset', 1);
            this.toastService.showSuccess('generalOfferType.deleted');
            this.router.navigateToRoute('general-offer-type-list');
          })
          .catch((err) => this.errorService.handleError(err));
        }
      );
  }

  private gotoParentView() {
    this.eventAggregator.publish('generalOfferTypeListReset', 1);
    this.router.navigateToRoute('general-offer-type-list');
  }
}
