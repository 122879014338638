import { DialogService, DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CreateNetDto } from 'models/NetModels';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { GeneralOfferService } from 'services/general-offer-service';
import { NetService } from 'services/net-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';

@autoinject
export class GeneralOfferEdit {

  private generalOffer: Models.GeneralOffer = new Models.GeneralOffer();
  private originalObject: any;
  private saving: boolean = false;
  private generalOfferId: number;
  private locked: boolean = false;

  constructor(
    private errorService: ErrorService,
    private utility: Utility,
    private dialogService: DialogService,
    private prompt: Prompt,
    private validationController: ValidationController,
    private validator: Validator,
    private generalOfferService: GeneralOfferService,
    private toastService: ToastService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService,
    private dialogController: DialogController,
    private i18n: I18N
  ) {

  }

  private activate(params) {
    this.getGeneralOffer(params.Id);
  }

  private canDeactivate() {

    // NOTE: Will react even if you hade made no changes.
    // See issue #303.

    if (!this.originalObject || this.saving) {
      return true;
    }

    // fix dates
    this.generalOffer = this.utility.areEqualDateFix(this.generalOffer, [{ key: 'PlannedDeliveryDate' }]);
    this.originalObject = this.utility.areEqualDateFix(this.originalObject, [{ key: 'PlannedDeliveryDate' }]);
    this.generalOffer = this.utility.areEqualDateFix(this.generalOffer, [{ key: 'NextFollowUp' }]);
    this.originalObject = this.utility.areEqualDateFix(this.originalObject, [{ key: 'NextFollowUp' }]);
    this.generalOffer = this.utility.areEqualDateFix(this.generalOffer, [{ key: 'ValidTo' }]);
    this.originalObject = this.utility.areEqualDateFix(this.originalObject, [{ key: 'ValidTo' }]);

    if (!this.utility.areEqual(this.generalOffer, this.originalObject, true)) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: {
          header: 'dialog.subFormOpenHeading',
          message: 'dialog.subFormOpenMessage',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel'  },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.continue'  }
          }
        }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      return true;
    }
  }

  private getGeneralOffer(generalOfferId) {
    this.generalOfferService
    .get(generalOfferId)
    .then((res: any) => {
      this.generalOffer = res;
      this.originalObject = JSON.parse(JSON.stringify(res));

      if (res.CanEdit === false) {
        this.locked = true;
      }

      ValidationRules
      .ensure('CustomerId').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('SegmentId').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('InternalDepartmentId').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('OfferStatusId').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('GeneralOfferTypeId').required().withMessage(this.i18n.tr('general.requiredField'))
      .on(this.generalOffer);

    })
    .catch((err) => this.errorService.handleError(err));
  }

  private saveGeneralOffer() {
    this.saving = true;

    delete this.generalOffer.Segment;
    delete this.generalOffer.InternalDepartment;
    delete this.generalOffer.GeneralOfferType; 
    delete this.generalOffer.Customer;
    delete this.generalOffer.Producer;
    delete this.generalOffer.OfferStatus;
    
    this.generalOfferService
    .put(this.generalOffer, this.generalOffer.Id)
    .then((res) => {
      this.toastService.showSuccess('generalOffer.saved');
      this.router.navigateToRoute('general-offers');
      this.eventAggregator.publish('generalOffersListReset');
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private validateBeforeSave() {
    this.validator
    .validateObject(this.generalOffer)
    .then((result) => {
      const errors = result.filter((validateResult) => {
        return !validateResult.valid;
      });
      if (errors.length > 0) {
        this.validationController.validate();
      } else {
        this.saveGeneralOffer();
      }
    });
  }

  private deleteService() {
    this.deleteDialogService.confirmBeforeDelete(() => {
      this.generalOfferService
      .delete(this.generalOffer.Id)
      .then((res) => {
        this.eventAggregator.publish('generalOffersListReset');
        this.router.navigateToRoute('general-offers');
        this.toastService.showSuccess('generalOffer.deleted');
      })
      .catch((err) => {
        this.errorService.handleError(err);
      });
    });
  }

}
