import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { FloaterTypeService } from 'services/floater-type-service';

@autoinject
export class FloaterTypeNew {
  private floaterType: Models.FloaterType = new Models.FloaterType();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private floaterTypeService: FloaterTypeService
  ) {}

  
  private createFloaterType() {

    this.floaterTypeService
      .post(this.floaterType)
      .then((res) => {
        this.eventAggregator.publish('floaterTypeListReset', 1);
        this.toastService.showSuccess('floaterType.created');
        this.router.navigateToRoute('floater-type-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
